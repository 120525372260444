@import "~antd/dist/antd.css";

$dark-background: #001529;
$background: #F0F2F5;

/// Layout

#components-layout-demo-custom-trigger {
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff; } }
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px; } }
.ant-layout-sider-children {
  .logo {
    text-align: center;
    .logo-img {
      max-width: 200px;
      max-height: 60px;
      padding: 10px 10px 20px 10px; } } }
.logo-img-log {
  max-width: 200px;
  max-height: 60px;
  padding: 10px 10px 20px 10px; }
.site-layout {
  .site-layout-background {
    background: #fff; } }
.content-wrapper {
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px; }
.content-wrapper-margin-less {
  margin: 0; }

.menu-float-right {
  float: right; }
.is-fullheight {
  min-height: 100vh; }
.theme-background {
  background-color: $background; }

/// IFrame

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 1rem;
  height: 0; }
.iframe-container iframe {
  position: absolute;
  top : 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px black solid; }
.iframe-container {
  .vimeo {
    position: absolute;
    top : 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px black solid; } }

.dark-button {
  background-color: $dark-background; }

.is-marginless {
  margin: 0; }
.align-center {
  text-align: center; }

.upsell-card {
  background-color: #F0F2F5 !important; }
.upsell-title {
  color: #A14EF6 !important; }

/// FAQ

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: $dark-background;
  border-radius: 2px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden; }

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: white;
  font-weight: normal; }

// Whitelabel
.search-input {
  span {
    input {
      border: 1px solid $dark-background;
      border-radius: 11px; }
    span {
      button {
        background-color: $dark-background; } } } }
.accounts-card {
  .ant-card-head {
    background-color: $dark-background;
    color: $background;
    font-weight: bold;
    border-radius: 11px; } }

// Sign up
.blue-card {
  border: 9px solid #407BFF;
  background: #407BFF87 0% 0% no-repeat padding-box; }

.black-divider {
  border-top-color: #000;
  margin: 20px 0 0 0; }

.link-hover {
  &:hover {
    color: #1890ff; } }

@media screen and (max-width: 768px) {
  .content-wrapper {
    margin: 4px 0; }
  .logo-img {
    max-width: 200px; } }
